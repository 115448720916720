export interface AppliedJob {
  date: string;
  job: string;
}

export const APPLIED_JOBS_COOKIE_NAME = "applied-jobs";
export const APPLIED_JOB_COOKIE_MAX_AGE = 60 * 60 * 24 * 365; // 1 year

export const getAppliedJobs = () => {
  const appliedJobsCookie =
    typeof window !== "undefined"
      ? localStorage.getItem(APPLIED_JOBS_COOKIE_NAME)
      : null;
  return appliedJobsCookie
    ? (JSON.parse(appliedJobsCookie) as AppliedJob[])
    : [];
};

export const getPreviousApplication = (jobId: string) => {
  return getAppliedJobs().find((data) => data.job === jobId);
};

export const createAppliedJobsCookie = (jobId: string) => {
  if (typeof window !== "undefined") {
    const appliedJobs = getAppliedJobs();
    appliedJobs.push({
      date: new Date(),
      job: jobId,
    });

    localStorage.setItem(APPLIED_JOBS_COOKIE_NAME, JSON.stringify(appliedJobs));
  }
};
