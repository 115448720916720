"use client";

import InnerHTML from "dangerously-set-html-content";

import { HtmlParagraphFieldsFragment } from "@/graphql/sdk/__generated__";
import { cn } from "@/helpers/className";

export const HtmlParagraph = ({
  data,
  className,
}: {
  className?: string;
  data: HtmlParagraphFieldsFragment;
}) => {
  return (
    <div className={cn("paragraph--code container", className)}>
      <InnerHTML html={data.code} />
    </div>
  );
};

export default HtmlParagraph;
