import Image from "next/image";
import Link from "next/link";

import logo from "@/assets/images/logo-vivaldis.svg";
import DrupalImage from "@/components/atoms/DrupalImage";
import {
  ImageFieldsFragment,
  ImageStyleAvailable,
} from "@/graphql/sdk/__generated__";
import { cn } from "@/helpers/className";
import { getVariation } from "@/helpers/image";

interface TeaserProps {
  description?: string;
  media?: ImageFieldsFragment;
  link?: {
    internal: boolean;
    title: string;
    url: string;
  };
  title: string;
}

export const Teaser = ({ title, description, media, link }: TeaserProps) => {
  const image = media?.variations
    ? getVariation(media.variations, ImageStyleAvailable.W950Teaser)
    : null;
  const mobileImage = media?.variations
    ? getVariation(media.variations, ImageStyleAvailable.W1440TeaserMobile)
    : null;

  return (
    <div
      className={cn(
        "default-teaser relative flex flex-col items-stretch rounded-lg border bg-white transition-all hover:border-yellow-600 hover:shadow-lg",
      )}
    >
      <div className="relative flex aspect-video w-full shrink-0 items-center justify-center overflow-hidden rounded-t-lg bg-yellow">
        {image ? (
          <>
            <DrupalImage
              url={mobileImage.url}
              alt={media.alt}
              width={mobileImage.width}
              height={mobileImage.height}
              className="w-full rounded-2xl transition-transform duration-300 xl:hidden xl:group-hover:scale-105"
            />
            <DrupalImage
              url={image.url}
              alt={media.alt}
              width={image.width}
              height={image.height}
              sizes="33vw"
              className="hidden w-full rounded-2xl transition-transform duration-300 xl:block xl:group-hover:scale-105"
            />
          </>
        ) : (
          <Image
            src={logo}
            width={80}
            height={42}
            alt="Vivaldis Interim"
            className="w-[160px]"
          />
        )}
      </div>
      <div className="flex flex-1">
        <div className="flex h-full w-full flex-col p-6 lg:p-8">
          {title && (
            <h4 className={cn("h5 !mb-1 mt-0 break-words")}>{title}</h4>
          )}

          {description && (
            <div
              className="prose mb-4 mt-2 line-clamp-2 max-w-full max-w-none text-sm lg:text-base"
              dangerouslySetInnerHTML={{ __html: description.processed }}
            />
          )}
          {link && (
            <Link
              href={link.url}
              className="stretched-link mr-auto mt-auto pt-4"
            >
              <span className="link pb-1 text-sm no-underline">
                {link.title}
              </span>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};
