"use client";

import { ReactNode, useState } from "react";
import { Tab, Transition } from "@headlessui/react";

import LinkWithSuffix from "@/components/molecules/LinkWithSuffix";
import { FindJobsParagraphFieldsFragment } from "@/graphql/sdk/__generated__";
import { cn } from "@/helpers/className";
import { useTranslation } from "@/i18n/client";
import { AllowedLanguages } from "@/types/generic";

interface Props {
  className?: string;
  data: FindJobsParagraphFieldsFragment;
  locale: AllowedLanguages;
}

export const FindJobsParagraph = ({ className, data, locale }: Props) => {
  const [activeTab, setActiveTab] = useState(0);
  const { t } = useTranslation(locale);
  return (
    <div className={cn("paragraph--find-jobs container", className)}>
      <Tab.Group selectedIndex={activeTab} onChange={setActiveTab}>
        <div className="flex flex-col gap-6 rounded-2xl border bg-white pb-8 sm:gap-8 sm:pb-12 xl:gap-10 xl:pb-16">
          <div className="flex flex-col items-center gap-8 self-stretch p-10 pb-0 pt-8 sm:pt-8 md:border-b md:py-8 xl:p-10">
            <h3>{t("paragraph.find_jobs.title")}</h3>

            <Tab.List className="flex justify-evenly md:hidden">
              {data.perSector?.length && (
                <Tab
                  className={cn(
                    "border-b-4 border-transparent px-4 pb-2 pt-2 text-center transition-colors hover:border-green-300 focus-visible:!outline-none",
                    activeTab === 0 && "border-green-300",
                  )}
                >
                  {t("paragraph.find_jobs.per_sector")}
                </Tab>
              )}
              {data.perFunction?.length && (
                <Tab
                  className={cn(
                    "border-b-4 border-transparent px-4 pb-2 pt-2 text-center transition-colors hover:border-green-300 focus-visible:!outline-none",
                    activeTab === 1 && "border-green-300",
                  )}
                >
                  {t("paragraph.find_jobs.per_function")}
                </Tab>
              )}
            </Tab.List>
          </div>

          <Tab.Panels className="flex overflow-hidden px-8 md:hidden">
            {data.perSector?.length && (
              <Tab.Panel>
                <TabContent active={activeTab === 0}>
                  <ListWithTitle
                    items={data.perSector}
                    title={t("paragraph.find_jobs.per_sector")}
                  />
                </TabContent>
              </Tab.Panel>
            )}
            {data.perFunction?.length && (
              <Tab.Panel>
                <TabContent active={activeTab === 1}>
                  <ListWithTitle
                    items={data.perFunction}
                    title={t("paragraph.find_jobs.per_function")}
                  />
                </TabContent>
              </Tab.Panel>
            )}
          </Tab.Panels>

          <div className="hidden grid-cols-2 gap-12 px-8 md:grid">
            <ListWithTitle
              items={data.perSector}
              title={t("paragraph.find_jobs.per_sector")}
            />
            <ListWithTitle
              items={data.perFunction}
              title={t("paragraph.find_jobs.per_function")}
            />
          </div>
        </div>
      </Tab.Group>
    </div>
  );
};

const TabContent = ({
  active,
  children,
}: {
  active: boolean;
  children: ReactNode;
}) => {
  return (
    <Transition
      show={active}
      enter="transition duration-300"
      enterFrom="lg:opacity-0 lg:-translate-x-4"
      enterTo="opacity-1 translate-x-0"
      leave="transition duration-150"
      leaveFrom="opacity-1"
      leaveTo="lg:opacity-0 lg:translate-x-4"
      className=""
    >
      {children}
    </Transition>
  );
};

const ListWithTitle = ({ items, title }: { items: any[]; title: string }) => {
  return (
    <div className="">
      <h5 className="!mb-4 hidden md:block">{title}</h5>
      <ul
        className={cn(
          "space-y-4 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-4 lg:space-y-0 xl:gap-x-12",
        )}
      >
        {items.map((item, index: number) => (
          <li key={index} className="truncate">
            <LinkWithSuffix link={item} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FindJobsParagraph;
