"use client";

import dynamic from "next/dynamic";
import Image from "next/image";
import Link from "next/link";
import { useSession } from "next-auth/react";

import logo from "@/assets/images/logo-vivaldis.svg";
import Badge from "@/components/atoms/Badge";
import Tag from "@/components/atoms/Tag";
import SaveJobButton from "@/components/molecules/SaveJobButton";
import { Job } from "@/graphql/sdk/__generated__";
import { cn } from "@/helpers/className";
import { getPreviousApplication } from "@/helpers/cookies";
import { generateJobUrl } from "@/helpers/url";
import { useTranslation } from "@/i18n/client";
import { AllowedLanguages } from "@/types/generic";

interface JobCardProps {
  className?: string;
  job: Job;
  locale: AllowedLanguages;
  small?: boolean;
}

// WARNING: don't make this ASYNC! It will mess up job overview (load more).
export const JobCard = ({
  job,
  locale,
  small = false,
  className,
}: JobCardProps) => {
  const { data: isLoggedIn } = useSession();
  const { t } = useTranslation(locale);
  const applied = getPreviousApplication(job.id);
  const VisitedBadge = (
    <Badge color={"green"} icon="eye">
      {t("job.viewed")}
    </Badge>
  );

  const VisitedNoSSR = dynamic(
    () => import("@/components/molecules/Visited.tsx"),
    { ssr: false },
  );

  return (
    <div
      key={job.id}
      className={cn(
        "relative flex flex-col rounded-lg border bg-white shadow transition-all hover:border-yellow-600 hover:shadow-lg",
        job.internal ? "group border-4 border-yellow" : "",
        className,
      )}
    >
      {job.internal && (
        <div className="flex items-center justify-between bg-yellow px-6 py-1.5 transition-colors group-hover:bg-yellow-600 lg:px-10 lg:py-4">
          <h5 className="!mb-0">{t("job.internal")}</h5>
          <Image
            src={logo}
            width={80}
            height={42}
            alt="Vivaldis Interim"
            className="w-[80px]"
          />
        </div>
      )}
      <div className={cn("p-6 pb-5", !small && "lg:p-10 lg:pb-9")}>
        <div className="flex items-start justify-between gap-2">
          <div className="max-w-full">
            <div className="mb-4 flex flex-wrap items-start justify-start gap-2 empty:hidden lg:mb-6">
              {job.urgent && (
                <Badge color={"orange"} icon="fire">
                  {t("job.urgent")}
                </Badge>
              )}
              {applied ? (
                <Badge color={"brown"} icon="check">
                  {t("job.applied_on")}{" "}
                  {new Date(applied.date).toLocaleDateString(locale)}
                </Badge>
              ) : (
                <VisitedNoSSR
                  VisitedComponent={VisitedBadge}
                  pageId={job.id}
                  pageType={"job"}
                  writeCookie={false}
                />
              )}
            </div>

            <h4
              className={cn("h5 !mb-1", !small && "lg:h4 break-words lg:!mb-2")}
            >
              {job.title}
            </h4>

            <div
              className={cn(
                "text-sm text-black-300",
                !small && "font-medium lg:text-lg",
              )}
            >
              {job.city}
            </div>
          </div>

          {isLoggedIn && <SaveJobButton jobId={job.id} />}
        </div>

        <hr className="my-3 lg:my-4" />

        {job.description && (
          <div
            className={cn(
              "prose line-clamp-3 max-w-3xl max-w-none text-sm text-black-300",
              !small && "lg:text-base",
            )}
            dangerouslySetInnerHTML={{
              __html: job.description.replace(/<\/?[^>]+(>|$)/g, ""),
            }}
          ></div>
        )}
      </div>

      <div
        className={cn(
          "mt-auto flex flex-wrap items-center gap-2 border-t px-6 py-4",
          !small && "lg:px-10 lg:py-6",
        )}
      >
        <Tag color={"gray"} icon={"tag"} small={small}>
          {t(`filter.option.${job.specialty}`)}
        </Tag>

        <Tag color={"gray"} icon={"tag"} small={small}>
          {t(`filter.option.${job.sector_group}`)}
        </Tag>
        <Tag color={"gray"} icon={"clock"} small={small}>
          {t(`filter.option.${job.regime}`)}
        </Tag>
        {job.salary_min && job.salary_max && (
          <Tag color={"gray"} icon={"coin"} small={small}>
            €&thinsp;{job.salary_min}&thinsp;-&thinsp;{job.salary_max} /&thinsp;
            {t(`period.${job.salary_period}`)}
          </Tag>
        )}
        <Tag color={"gray"} icon={"calendar"} small={small}>
          {t(`filter.option.${job.type}`)}
        </Tag>
      </div>

      <Link
        href={generateJobUrl(locale, job.title, job.id)}
        className="stretched-link"
        prefetch={false}
      />
    </div>
  );
};
