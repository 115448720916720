import DrupalImage from "@/components/atoms/DrupalImage";
import {
  ImageStyleAvailable,
  ParagraphTestimonial,
} from "@/graphql/sdk/__generated__";
import { cn } from "@/helpers/className";
import { getVariation } from "@/helpers/image";

interface TestimonialProps {
  className?: string;
  data: ParagraphTestimonial;
  textLight?: boolean;
}

export const Testimonial = ({
  data,
  className,
  textLight,
}: TestimonialProps) => {
  let image = null;

  if (data.authorImage?.imageMediaImage?.variations) {
    image = getVariation(
      data.authorImage?.imageMediaImage?.variations,
      ImageStyleAvailable.Avatar,
    );
  }

  return (
    <div className={cn("flex flex-col items-center text-center", className)}>
      <div
        className={cn(
          "prose mb-5 max-w-full text-lg text-black-400",
          textLight && "prose-invert !text-white",
        )}
        dangerouslySetInnerHTML={{ __html: data.text.processed }}
      ></div>

      {data.authorImage && image && (
        <DrupalImage
          url={image.url}
          alt={data.authorImage.imageMediaImage.alt ?? ""}
          width={image.width}
          height={image.height}
          className={"mx-auto mb-2 mt-2 w-20 rounded-full"}
          priority
        />
      )}
      {data.author && (
        <strong
          className={cn("mb-1 block font-display", textLight && "text-white")}
        >
          {data.author}
        </strong>
      )}
      {data.functionTitle && (
        <div
          className={cn("text-sm text-black-300", textLight && "text-white/80")}
        >
          {data.functionTitle}
        </div>
      )}
    </div>
  );
};

export default Testimonial;
