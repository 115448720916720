"use client";

import { JobCard } from "@/components/molecules/JobCard";
import { Job } from "@/graphql/sdk/__generated__";
import { cn } from "@/helpers/className";
import { AppliedJob } from "@/helpers/cookies";
import { AllowedLanguages } from "@/types/generic";

interface JobsListProps {
  appliedJobs?: AppliedJob[];
  className?: string;
  jobs: Job[];
  locale: AllowedLanguages;
}

export const JobsList = ({ locale, jobs, className }: JobsListProps) => {
  return (
    <div
      className={cn(
        "flex grid-cols-1 gap-4 overflow-scroll pb-4 pr-4 md:grid md:grid-cols-2 md:overflow-visible lg:pb-0 lg:pr-0 xl:grid-cols-4 xl:gap-8",
        className,
      )}
    >
      {jobs.map((job) => (
        <JobCard job={job} locale={locale} key={job.id} small />
      ))}
    </div>
  );
};

export default JobsList;
