"use client";

import { useMemo } from "react";

import DrupalImage from "@/components/atoms/DrupalImage";
import {
  FiveBlocksParagraphFieldsFragment,
  ImageStyleAvailable,
} from "@/graphql/sdk/__generated__";
import { cn } from "@/helpers/className";
import { getVariation } from "@/helpers/image";

interface FiveBlocksParagraphProps {
  className?: string;
  data: FiveBlocksParagraphFieldsFragment;
}

export const FiveBlocksParagraph = ({
  data,
  className,
}: FiveBlocksParagraphProps) => {
  const imageForBlock2 = useMemo(() => {
    if (!data.imageForBlock2?.imageMediaImage?.variations) {
      return null;
    }
    return getVariation(
      data.imageForBlock2.imageMediaImage.variations,
      ImageStyleAvailable.W2000,
    );
  }, [data.imageForBlock2?.imageMediaImage?.variations]);

  const imageForBlock3 = useMemo(() => {
    if (!data.imageForBlock3?.imageMediaImage?.variations) {
      return null;
    }
    return getVariation(
      data.imageForBlock3.imageMediaImage.variations,
      ImageStyleAvailable.Thumbnail,
    );
  }, [data.imageForBlock3?.imageMediaImage?.variations]);

  const imageForBlock4 = useMemo(() => {
    if (!data.imageForBlock4?.imageMediaImage?.variations) {
      return null;
    }
    return getVariation(
      data.imageForBlock4.imageMediaImage.variations,
      ImageStyleAvailable.Thumbnail,
    );
  }, [data.imageForBlock4?.imageMediaImage?.variations]);

  const imageForBlock5 = useMemo(() => {
    if (!data.imageForBlock5?.imageMediaImage?.variations) {
      return null;
    }
    return getVariation(
      data.imageForBlock5.imageMediaImage.variations,
      ImageStyleAvailable.Thumbnail,
    );
  }, [data.imageForBlock5?.imageMediaImage?.variations]);

  return (
    <div className={cn(className, "paragraph--five-blocks container")}>
      <div className="sm:grid-flow-cols grid grid-flow-row gap-8 sm:grid-cols-3 sm:gap-12">
        <div className="flex flex-col gap-6 sm:gap-8">
          <h2>{data.titleForBlock1}</h2>
          {data.textForBlock1?.processed && (
            <div
              className="prose"
              dangerouslySetInnerHTML={{
                __html: data.textForBlock1.processed,
              }}
            />
          )}
        </div>
        <div className="col-span-1 mb-4 flex flex-col sm:col-span-2 sm:mb-0">
          {data.textForBlock2?.processed && (
            <div
              className="prose"
              dangerouslySetInnerHTML={{
                __html: data.textForBlock2.processed,
              }}
            />
          )}
          {imageForBlock2 && (
            <DrupalImage
              url={imageForBlock2.url}
              alt={data.imageForBlock2?.imageMediaImage?.alt ?? ""}
              width={imageForBlock2.width}
              height={imageForBlock2.height}
              className={"w-full rounded-3xl"}
            />
          )}
        </div>
        <div className="flex flex-col gap-6 border-t border-solid border-black pt-6 sm:gap-8 sm:pt-8">
          {imageForBlock3 && (
            <DrupalImage
              url={imageForBlock3.url}
              alt={data.imageForBlock3?.imageMediaImage?.alt ?? ""}
              width={imageForBlock3.width}
              height={imageForBlock3.height}
              className="rounded-3xl"
            />
          )}
          <h5 className="text-black-400">{data.titleForBlock3}</h5>
          <div
            className="prose"
            dangerouslySetInnerHTML={{
              __html: data.textForBlock3.processed,
            }}
          />
        </div>
        <div className="flex flex-col gap-6 border-t border-solid border-black pt-6 sm:gap-8 sm:pt-8">
          {imageForBlock4 && (
            <DrupalImage
              url={imageForBlock4.url}
              alt={data.imageForBlock4?.imageMediaImage?.alt ?? ""}
              width={imageForBlock4.width}
              height={imageForBlock4.height}
              className="rounded-3xl"
            />
          )}
          <h5 className="text-black-400">{data.titleForBlock4}</h5>
          <div
            className="prose"
            dangerouslySetInnerHTML={{
              __html: data.textForBlock4.processed,
            }}
          />
        </div>
        <div className="flex flex-col gap-6 border-t border-solid border-black pt-6 sm:gap-8 sm:pt-8">
          {imageForBlock5 && (
            <DrupalImage
              url={imageForBlock5.url}
              alt={data.imageForBlock5?.imageMediaImage?.alt ?? ""}
              width={imageForBlock5.width}
              height={imageForBlock5.height}
              className="rounded-3xl"
            />
          )}
          <h5 className="text-black-400">{data.titleForBlock5}</h5>
          <div
            className="prose"
            dangerouslySetInnerHTML={{
              __html: data.textForBlock5.processed,
            }}
          />
        </div>
      </div>
    </div>
  );
};
