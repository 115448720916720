import Icon from "@/components/atoms/Icon";

export const PlayBtn = () => {
  return (
    <button
      className="mb-4 ml-auto mr-4 mt-auto inline-flex h-12 w-12 items-center justify-center rounded-full bg-green-300/90 transition-all hover:scale-110 hover:bg-green hover:shadow-xl md:mb-auto md:mr-auto lg:h-20 lg:w-20"
      title="Play"
    >
      <Icon
        name="play-fill"
        className="relative h-6 w-6 translate-x-[.12rem] text-white lg:h-8 lg:w-8 lg:translate-x-[.1rem]"
      />
    </button>
  );
};

export default PlayBtn;
