import Icon from "@/components/atoms/Icon";
import NewsletterForm from "@/components/forms/NewsletterForm";
import { cn } from "@/helpers/className";
import { AllowedLanguages } from "@/types/generic";

interface NewsletterProps {
  className?: string;
  locale: AllowedLanguages;
  location?: string;
  searchQuery?: string;
  title: string;
}

export const Newsletter = ({
  className,
  title,
  locale,
  location,
  searchQuery,
}: NewsletterProps) => {
  return (
    <div className={cn("relative", className)}>
      <div className="absolute top-0 grid aspect-square w-20 -translate-y-1/2 place-items-center rounded-full bg-yellow">
        <Icon name="paperplane" className="h-8 w-8 text-black" />
      </div>
      <h4
        className="text-white lg:!mb-10"
        dangerouslySetInnerHTML={{ __html: title }}
      ></h4>
      <NewsletterForm
        locale={locale}
        location={location}
        searchQuery={searchQuery}
      />
    </div>
  );
};

export default Newsletter;
