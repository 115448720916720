"use client";

import { Splide, SplideSlide } from "@splidejs/react-splide";

import Button from "@/components/atoms/Button";
import { ChildParagraph } from "@/components/paragraphs/child-paragraphs/ChildParagraph";
import { cn } from "@/helpers/className";

import "@splidejs/splide/dist/css/splide-core.min.css";

export const TeaserParagraph = ({ data, className }) => {
  return (
    <div
      className={cn(
        className,
        "paragraph--teaser break-free overflow-x-hidden",
      )}
    >
      <div className="container lg:container">
        {data.title && (
          <h2 className="!mb-10 text-center lg:!mb-16">{data.title}</h2>
        )}

        <div className="-ml-4 pl-8">
          <Splide
            options={{
              arrows: false,
              gap: "1rem",
              perPage: 1,
              mediaQuery: "min",
              pagination: true,
              breakpoints: {
                480: {
                  perPage: 2,
                },
                960: {
                  perPage: 3,
                },
                1280: {
                  perPage: 4,
                },
                1600: {
                  perPage: 5,
                },
              },
              classes: {
                pagination: "splide__pagination !justify-start gap-2 !mt-6",
                page: "splide__pagination__page w-[0.65rem] h-[0.65rem] rounded-full bg-border",
              },
            }}
          >
            {data.content.map((teaser, index) => (
              <SplideSlide key={index}>
                <ChildParagraph
                  key={teaser.id}
                  data={teaser}
                  type={teaser.__typename}
                />
              </SplideSlide>
            ))}
          </Splide>
        </div>

        {data.paragraphTeasersLink && (
          <div className="mt-12 text-center">
            <Button
              href={data.paragraphTeasersLink.url}
              className="btn-lg"
              as="link"
            >
              {data.paragraphTeasersLink.title}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
