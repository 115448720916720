"use client";

import React, { useRef } from "react";
import { useInViewport } from "react-in-viewport";

import { QuoteParagraphFieldsFragment } from "@/graphql/sdk/__generated__";
import { cn } from "@/helpers/className";

export const QuoteParagraph = ({
  data,
  className,
}: {
  className?: string;
  data: QuoteParagraphFieldsFragment;
}) => {
  const blockQuoteRef = useRef();
  const { inViewport } = useInViewport(
    blockQuoteRef,
    {},
    { disconnectOnLeave: false },
  );

  return (
    <div className={cn("paragraph--quote container", className)}>
      <div className="grid grid-cols-12">
        <div className="col-span-12 flex flex-col justify-center md:col-span-10 md:col-start-2 lg:col-span-8 lg:col-start-3 2xl:col-span-6 2xl:col-start-4">
          <div className="mx-auto inline-flex flex-col">
            <blockquote
              className={cn(
                "prose inline-flex max-w-full flex-col",
                inViewport && "in-viewport",
              )}
              dangerouslySetInnerHTML={{
                __html: data.text.processed,
              }}
              ref={blockQuoteRef}
            ></blockquote>
            {data.author && <p className="blockquote__author">{data.author}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuoteParagraph;
